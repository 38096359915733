export enum DimensionsEnum {
  AgeGroup5 = 'ageGroup5',
  Gender = 'gender',
  ZipCode = 'zipCode',
  IrisCode = 'irisCode',
  IncomeGroup = 'incomeGroup',
  Department = 'department',
  District = 'district',
  FamilySquare = 'familySquare',
}

export enum GeographicalDimensionEnum {
  Zip = 'zipCode',
  Iris = 'irisCode',
  Department = 'department',
  District = 'district',
  City = 'city',
}

export enum GeographicalTargetGroupingEnum {
  TargetCity = 'targetCity',
  TargetCoordinates = 'targetCoordinates',
  ZipCode = 'zipCode',
}

import { createActionGroup, emptyProps, props } from '@ngrx/store';

import { CountModel } from '../models/count.model';
import { DatasetTypeEnum } from '../models/enum/dataset-type.enum';
import { DimensionsEnum } from '../models/enum/dimensions.enum';
import { CountFilterModel } from '../models/filter-operator.model';
import { ImportObject } from '../models/import.model';

export const profileActions = createActionGroup({
  source: 'Profile',
  events: {
    'Get target reach': props<{
      organizationId: string;
      accountId: string;
      filters: CountFilterModel;
      withLabel: boolean;
      dimensions?: DimensionsEnum[];
    }>(),
    'Target reach retrieved successfully': props<{
      count: number;
      counts: CountModel[];
    }>(),
    'Target reach failed to be retrieved': props<{ error: unknown }>(),

    'Upload dataset with campaign ids': props<{
      organizationId: string;
      accountId: string;
      campaignIds?: string[];
      datasetType: DatasetTypeEnum;
      files?: File[];
    }>(),
    'Dataset with campaign ids sent successfully': props<{
      datasetId: string;
      datasetName: string;
      datasetMode: DatasetTypeEnum;
    }>(),
    'Dataset with campaign ids failed to be validated': props<{
      error: unknown;
    }>(),
    'Reset Dataset store': emptyProps(),

    'Import profile': props<{
      organizationId: string;
      accountId: string;
      importObject: ImportObject;
    }>(),
    'Import profile successfully': props<{
      importProfileId: string;
    }>(),
    'Import profile failed to be upload': props<{
      error: unknown;
    }>(),

    'Get import profile': props<{
      organizationId: string;
      accountId: string;
      profileImportId: string;
    }>(),
    'Import profile retrieved successfully': props<{
      name: string;
      profileCount: number;
    }>(),
    'Import profile failed to be retrieved': props<{ error: unknown }>(),

    'Export CSV': props<{
      organizationId: string;
      accountId: string;
      fields: string[];
      name: string;
      filters: CountFilterModel;
    }>(),
    'CSV exported successfully': emptyProps,
    'CSV failed to be exported': props<{ error: unknown }>(),
    'reset counting details': emptyProps(),
    'close counting subscription': emptyProps(),
  },
});
